import { FC } from "react";
import Link from "next/link";
import Image from "next/image";
import { minimalTimeSinceFormatter } from "@utils/formatters/timeSinceFormatter";
import {
  Compensation,
  JobPostRemoteType,
  Organisation,
  JobPost,
  Location,
  Remote,
} from "@prisma/client";
import { classNames } from "@utils/classNames";
import { getSlug } from "@utils/db/JobPost/getSlug";
import { organisationSizeToLabel } from "@utils/enumToLabel/organisationSizeToLabel";
import {
  BanknotesIcon,
  GlobeAltIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { OnsiteLocationsBlock } from "./blocks/OnsiteLocationsBlock";
import { RemoteLocationsBlock } from "./blocks/RemoteLocationsBlock";
import { getCompensationAsString } from "@utils/db/Compensation/getCompensationAsString";
import { useRouter } from "next/router";
import { stringify } from "querystring";
import { AssignedTag } from "types/tags";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

interface Props {
  jobPost: JobPost & {
    compensation: Compensation;
    hiringOrganisation: Organisation;
    locations: Location[];
    remote: Remote;
    isSticky?: boolean;
  };
  hasSize?: boolean;
  hasOrganisationAbout?: boolean;
  cN?: string;
}

export const JobPostPreview: FC<Props> = ({
  jobPost,
  hasSize = false,
  hasOrganisationAbout = false,
  cN = "",
}) => {
  const router = useRouter();
  const urlSearchParams =
    Object.values(router.query).length > 0
      ? `?${new URLSearchParams(stringify(router.query))}`
      : "";

  // Tags.
  const tags = jobPost.userAssignedTags as unknown as AssignedTag[];
  const isFullTime = tags.map((t) => t.id).includes("full-time");
  const isPartTime = tags.map((t) => t.id).includes("part-time");
  const isContract = tags.map((t) => t.id).includes("contract");
  const isInternship = tags.map((t) => t.id).includes("internship");

  return (
    <li className={classNames("flex justify-between", cN)}>
      <div className="flex flex-row w-full">
        <Link
          href={`/company/${jobPost.hiringOrganisation?.slug}${urlSearchParams}`}
          className="shrink-0 w-11 sm:w-16"
        >
          {jobPost.hiringOrganisation?.favicon ? (
            <Image
              className="rounded w-9 sm:w-12"
              width={48}
              height={48}
              src={`${process.env.NEXT_PUBLIC_SUPABASE_PROJECT_URL}/storage/v1/object/public/public${jobPost?.hiringOrganisation?.favicon}`}
              alt={`${jobPost?.hiringOrganisation?.name || ""} logo`}
            />
          ) : (
            <div className="flex justify-center items-center w-10 h-10 rounded bg-gray-100 ">
              {jobPost.hiringOrganisation?.name?.[0]}
            </div>
          )}
        </Link>

        <div className="w-full">
          {/* hiring org */}
          <div className="w-full">
            <div className="flex flex-row justify-between">
              <div className="">
                <Link
                  href={`/company/${jobPost.hiringOrganisation?.slug}${urlSearchParams}`}
                  className="inline font-black hover:underline"
                >
                  <h3 className="inline">
                    {jobPost.hiringOrganisation?.name
                      ? jobPost.hiringOrganisation?.name
                      : jobPost.hiringOrganisation?.webUrl?.replace(
                          "https://",
                          ""
                        )}
                  </h3>
                </Link>
                {hasSize && jobPost.hiringOrganisation?.size ? (
                  <span className="ml-2 px-1 py-0.5 text-gray-600 bg-gray-100 rounded-sm text-xxs font-medium">
                    {organisationSizeToLabel[jobPost.hiringOrganisation?.size]}
                  </span>
                ) : null}
              </div>
              <p className="pl-1 text-xxs text-gray-300">
                {jobPost.isSticky
                  ? "featured"
                  : minimalTimeSinceFormatter(new Date(jobPost.createdAt))}
              </p>
            </div>

            {hasOrganisationAbout && jobPost.hiringOrganisation?.about && (
              <p className="mt-0.5">{jobPost.hiringOrganisation?.about}</p>
            )}
          </div>

          {/* ------------------ */}
          {/* Job details. */}
          {/* ------------------ */}
          <Link
            href={`/job/${getSlug({
              shortId: jobPost.shortId,
              title: jobPost.title,
              organisationName: jobPost.hiringOrganisation?.name,
            })}${urlSearchParams}`}
          >
            <h4 className="mt-3 mb-2 text-base font-bold leading-tight text-blue-600 hover:underline">
              {jobPost.title}
            </h4>
          </Link>

          {(isFullTime || isPartTime || isContract || isInternship) && (
            <div className="my-1 text-xs">
              <WorkOutlineIcon
                classes={{
                  fontSizeInherit: "text-base",
                }}
                fontSize="inherit"
                className="inline w-4 h-4 mr-1"
              />
              {isFullTime && (
                <a
                  href="/search?tags=full-time"
                  className="hover:underline"
                  rel="nofollow"
                >
                  <span className="pr-2">Full-time</span>
                </a>
              )}
              {isPartTime && (
                <a
                  href="/search?tags=part-time"
                  className="hover:underline"
                  rel="nofollow"
                >
                  <span className="pr-2">Part-time</span>
                </a>
              )}
              {isContract && (
                <a
                  href="/search?tags=contract"
                  className="hover:underline"
                  rel="nofollow"
                >
                  <span className="pr-2">Contract</span>
                </a>
              )}
              {isInternship && (
                <a
                  href="/search?tags=internship"
                  className="hover:underline"
                  rel="nofollow"
                >
                  <span className="pr-2">Internship</span>
                </a>
              )}
            </div>
          )}

          <div className="items-center my-1 font-bold text-smX">
            {jobPost.compensation.min || jobPost.compensation.max ? (
              <>
                <BanknotesIcon className="inline w-4 h-4 mr-1" />
                {getCompensationAsString(jobPost.compensation)}
              </>
            ) : null}
          </div>

          <div className="text-black">
            {jobPost.locations?.length > 0 && (
              <div className="mb-1">
                <h5 className="inline mr-1">
                  <MapPinIcon className="inline w-4 h-4" /> Onsite locations:
                </h5>
                <OnsiteLocationsBlock locations={jobPost.locations} />
              </div>
            )}
            {(jobPost.remote?.type === JobPostRemoteType.WORLDWIDE ||
              jobPost.remote?.regions?.length > 0) && (
              <div className="mb-1">
                <h5 className="inline mr-1">
                  <GlobeAltIcon className="inline w-4 h-4" /> Hires remotely in:
                </h5>
                <RemoteLocationsBlock remote={jobPost.remote} />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
