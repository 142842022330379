import { Compensation } from "@prisma/client";
import { compensationUnitToLabel } from "@utils/enumToLabel/compensationUnitToLabel";
import { kFormatter } from "@utils/formatters/kFormatter";

// Format as:
// - USD 110k-$193k/year
// - USD 110k/year
// - GBP 100k/year
export const getCompensationAsString = ({
  min,
  max,
  currency,
  unit,
}: Compensation) => {
  // Return early if no compensation.
  if (!(max || min)) {
    return;
  }

  const compensationMaxFormatted = max ? kFormatter(`${max}`) : "";
  const compensationMinFormatted = min ? kFormatter(`${min}`) : "";
  const compensationUnit = compensationUnitToLabel[unit]?.toLowerCase();

  return `${currency} ${
    compensationMinFormatted && compensationMaxFormatted
      ? `${compensationMinFormatted}-${compensationMaxFormatted}`
      : compensationMinFormatted
      ? `from ${compensationMinFormatted}`
      : `up to ${compensationMaxFormatted}`
  }/${compensationUnit}`;
};
