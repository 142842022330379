import React, { FC } from "react";
import { Remote } from ".prisma/client";
import { JobPostRemoteType } from "@prisma/client";
import { getRegionById } from "@utils/regions/getRegionById";
import { classNames } from "@utils/classNames";
import Link from "next/link";

interface Props {
  remote: Remote;
}

export const RemoteLocationsBlock: FC<Props> = ({ remote }) => {
  return (
    <>
      {remote?.type === JobPostRemoteType.WORLDWIDE ? (
        <Link href={`/search`} className="hover:underline">
          Worldwide
        </Link>
      ) : (
        <ul className="inline">
          {remote?.regions.map((regionId, i, arr) => {
            const regionName = getRegionById(regionId)?.name;
            return (
              <li
                key={i}
                className={classNames(
                  "inline",
                  i < arr.length - 1 && "after:content-['•'] after:px-1"
                )}
              >
                <a
                  href={`/search?location=${regionName.replace(",", " ")}`}
                  className="hover:underline"
                  rel="nofollow"
                >
                  {regionName}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
