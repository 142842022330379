import { valueToUrlString } from "@utils/valueToUrlString";
import { countries } from "data/countries";
import { regions, timeZones } from "data/regions";

enum RegionType {
  COUNTRY = "COUNTRY",
  REGION = "REGION",
  TIMEZONE = "TIMEZONE",
}

export interface RemoteRegion {
  id: string;
  name: string;
  type: RegionType;
  countries?: string[];
}

export const remoteRegions: RemoteRegion[] = [
  ...Object.entries(countries).map(([key, value]) => ({
    id: key.toLocaleLowerCase(),
    name: value,
    type: RegionType.COUNTRY,
  })),
  ...Object.entries(regions).map(([key, value]) => ({
    id: valueToUrlString(value.name),
    name: value.name,
    countries: value.countries.map((country) => country.toLocaleLowerCase()),
    type: RegionType.REGION,
  })),
  ...Object.entries(timeZones).map(([key, value]) => ({
    id: valueToUrlString(key),
    name: key,
    countries: value.countries.map((country) => country.toLocaleLowerCase()),
    type: RegionType.TIMEZONE,
  })),
];
