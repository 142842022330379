import { getNormalisedUrlSlug } from "@utils/getNormalisedUrlSlug";

export const getSlug = ({
  shortId,
  title,
  organisationName,
}: {
  shortId: number | string;
  title: string;
  organisationName: string;
}): string =>
  getNormalisedUrlSlug(
    `${title}-at-${organisationName ? organisationName : ""}-${shortId}`
  );
