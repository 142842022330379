export const kFormatter = (numStr?: string): string => {
  const num = parseInt(numStr);
  if (typeof num !== "number") {
    return;
  }
  return Math.abs(num) > 9999
    ? (Math.abs(num) / 1000).toFixed(0) + "k"
    : Math.abs(num) > 999
    ? (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.abs(num) + "";
};
