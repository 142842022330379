import { formatDistanceToNowStrict } from "date-fns";

interface FormatDistanceToNowStrictOptions {
  addSuffix?: boolean;
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
  roundingMethod?: "floor" | "ceil" | "round";
  locale?: Locale;
}
export const timeSinceFormatter = (
  datetime?: Date,
  options?: FormatDistanceToNowStrictOptions
) => (datetime ? formatDistanceToNowStrict(datetime, options) : "-");

export const minimalTimeSinceFormatter = (datetime?: Date) =>
  datetime
    ? formatDistanceToNowStrict(datetime)
        .replace(" days", "d")
        .replace(" day", "d")
        .replace(" hours", "h")
        .replace(" hour", "h")
        .replace(" minutes", "m")
        .replace(" minute", "m")
        .replace(" seconds", "s")
        .replace(" second", "s")
    : "-";
