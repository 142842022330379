import React, { FC } from "react";
import { Location, Remote } from ".prisma/client";
import { classNames } from "@utils/classNames";
import Link from "next/link";

interface Props {
  locations?: Location[];
}

export const OnsiteLocationsBlock: FC<Props> = ({ locations }) => {
  return (
    <ul className="inline">
      {locations.map((loc, i, arr) => {
        const address = loc.formattedAddress || loc.address;
        return address ? (
          <li
            key={i}
            className={classNames(
              "inline",
              i < arr.length - 1 && "after:content-['•'] after:px-1.5"
            )}
          >
            <a
              href={`/search?location=${address.replace(",", " ")}`}
              className="hover:underline"
              rel="nofollow"
            >
              {address}
            </a>
          </li>
        ) : null;
      })}
    </ul>
  );
};
