import { OrganisationSize } from "@prisma/client";

export const organisationSizeToLabel = {
  [OrganisationSize.A]: "Self-employed",
  [OrganisationSize.B]: "1-10 employees",
  [OrganisationSize.C]: "11-50 employees",
  [OrganisationSize.D]: "51-200 employees",
  [OrganisationSize.E]: "201-500 employees",
  [OrganisationSize.F]: "501-1000 employees",
  [OrganisationSize.G]: "1001-5000 employees",
  [OrganisationSize.H]: "5001-10,000 employees",
  [OrganisationSize.I]: "10,001+ employees",
};
