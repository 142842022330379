import removeAccents from "remove-accents";

export const getNormalisedUrlSlug = (slug?: string): string | undefined => {
  return (
    slug &&
    removeAccents(slug)
      ?.toLowerCase()
      ?.replace(/[^a-z0-9]/g, "-")
      ?.replace(/-+/g, "-")
      ?.replace(/^-|-$/g, "")
  );
};
